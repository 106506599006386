import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Box, Typography } from '@mui/material'
import { useNavigate, useLocation, Link } from 'react-router-dom-v6'
import { useTranslation } from 'react-i18next'

import { SignInCard } from '../../SignInCard/SignInCard'
import { getStyles } from '../../SignInCard/SignInCard.styles'

import { Button, FormField, TextInput, Spinner } from '@percent/lemonade'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { verifyOtpKey } from '@percent/workplace-giving/api/auth/verifyOTPKey/verifyOTPKey'
import { useLogin } from '@percent/workplace-giving/common/hooks/useLogin/useLogin'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function SignWithTwoFA() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state: locationState }: { state: any } = useLocation()
  const { dispatch, state } = useAuth()
  const { login } = useLogin({ dispatch })
  const [errorMessage, setErrorMessage] = useState('')
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  useEffect(() => {
    if (!locationState?.loginAttempted) {
      navigate('/sign-in')
    }
  }, [locationState])

  useEffect(() => {
    if (state.status === 'authorized') {
      navigate('/')
    }
  }, [state.status])

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: verifyOtpKey,
    onSuccess: async ({ data: account }) => {
      try {
        await login({ account })
        navigate(AppRoute.HOME)
      } catch (e) {
        setErrorMessage(t('workplace_giving.errors.unexpected'))
      }
    }
  })

  const formik = useFormik({
    initialValues: {
      token: ''
    },
    validationSchema: () =>
      yup.object().shape({
        token: yup
          .string()
          .required('Required')
          .matches(/^[0-9]+$/, t('workplace_giving.errors.onlyDigits'))
          .min(6, t('workplace_giving.errors.sixDigit'))
          .max(6, t('workplace_giving.errors.sixDigit'))
      }),
    onSubmit: ({ token }: { token: string }) => {
      mutateAsync({
        token
      })
    }
  })

  const { errors, values, touched, handleChange, handleBlur, handleSubmit } = formik

  useEffect(() => {
    if (values.token) {
      setErrorMessage('')
    }
  }, [values.token, setErrorMessage])

  return (
    <SignInCard title={t('workplace_giving.2fa.title')}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner size="l" />
        </Box>
      ) : (
        <Box component="form" sx={Styles.Form} onSubmit={handleSubmit}>
          <FormField
            label={t('workplace_giving.2fa.enterSixDigit')}
            status={touched.token && errors.token ? 'danger' : 'default'}
            statusMessage={errorMessage || errors.token}
            data-testid="token"
          >
            <TextInput
              name="token"
              placeholder={t('workplace_giving.2fa.placeholderSixDigit')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.token}
            />
          </FormField>
          <Button type="submit" data-testid="auth-active-button">
            {t('workplace_giving.common.confirm')}
          </Button>
          <Typography variant="body2">
            <span>
              <Link to="/sign-in">{t('workplace_giving.2fa.signInDifferentAccount')}</Link>
            </span>
          </Typography>
        </Box>
      )}
    </SignInCard>
  )
}
