/* eslint-disable no-empty */
import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { Box } from '@mui/material'
import { Alpha3Code } from 'i18n-iso-countries'

import { AuthStateContext } from '../authContext/AuthContext'
import { Partner, Account } from '../authContext/AuthContext.types'
import {
  responseFailureInterceptor,
  responseSuccessInterceptor
} from '../../client/clientContextController/interceptors/responseInterceptors'
import { AppRoute } from '../../../routing/AppRoute.enum'
import { BASE_PALETTE } from '../../colorTheme/colorThemeContextController/ColorThemeContextController'

import { AuthContextControllerProps } from './AuthContextController.types'
import { useClient } from '@percent/workplace-giving/common/hooks'
import { Spinner } from '@percent/lemonade'
import { useLogin } from '@percent/workplace-giving/common/hooks/useLogin/useLogin'
import { AccountConfig } from '@percent/workplace-giving/api/account/accountConfig/accountConfig'
import { useRateLimit } from '@percent/workplace-giving/common/hooks/useRateLimit/useRateLimit'
import { useIdentify } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

const DEFAULT_STATE = {
  status: 'first_load' as const
}

const DEFAULT_COUNTRY_CODE = 'AUS'

export type AuthAction =
  | {
      type: 'RELOAD'
    }
  | {
      type: 'LOADING'
    }
  | {
      type: 'LOGOUT'
    }
  | {
      type: 'LOGIN'
      payload: {
        permissions: string[]
        account: Account
        partner: Partner
        countryCode?: Alpha3Code
        timeZone?: string
        acceptedTerms: string[]
        accConfig?: AccountConfig
        accountCountryCode: Alpha3Code | null
      }
    }
  | {
      type: 'UPDATE'
      payload: {
        account: Account
        partner: Partner
        countryCode?: string
        timeZone?: string
        acceptedTerms: string[]
        accConfig?: AccountConfig
      }
    }

export type AuthorizedAuthState = {
  status: 'authorized'
  permissions: string[]
  account: Account
  partner: Partner
  timeZone?: string
  countryCode?: string
  acceptedTerms: string[]
  accConfig?: AccountConfig
  accountCountryCode: Alpha3Code | null
}
export type AuthState =
  | {
      status: 'first_load'
    }
  | {
      status: 'unauthorized'
    }
  | {
      status: 'loading'
    }
  | AuthorizedAuthState

// eslint-disable-next-line consistent-return
function reducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'LOADING': {
      return {
        status: 'loading' as const
      }
    }
    case 'LOGIN': {
      return {
        status: 'authorized' as const,
        partner: action.payload.partner,
        account: action.payload.account,
        countryCode:
          (action.payload.countryCode as Alpha3Code) ||
          ((state.status === 'authorized' ? state.countryCode : DEFAULT_COUNTRY_CODE) as Alpha3Code),
        timeZone: action.payload.timeZone,
        permissions: action.payload.permissions,
        acceptedTerms: action.payload.acceptedTerms,
        accConfig: action.payload.accConfig,
        accountCountryCode: action.payload.accConfig?.country ?? null
      }
    }
    case 'LOGOUT': {
      return {
        status: 'unauthorized' as const
      }
    }
    case 'UPDATE': {
      if (state.status === 'authorized') {
        return {
          status: 'authorized' as const,
          partner: action.payload.partner,
          account: action.payload.account,
          countryCode: state.status === 'authorized' ? state.countryCode : DEFAULT_COUNTRY_CODE,
          timeZone: state.status === 'authorized' ? state.timeZone : undefined,
          permissions: state.status === 'authorized' ? state.permissions : [],
          acceptedTerms:
            state.status === 'authorized'
              ? Array.from(new Set([...state.acceptedTerms, ...action.payload.acceptedTerms]))
              : [],
          accConfig: {
            ...state.accConfig,
            ...action.payload.accConfig
          },
          accountCountryCode: state.accConfig?.country ?? null
        }
      }

      return state
    }
    case 'RELOAD': {
      return {
        status: 'first_load' as const
      }
    }
  }
}

export const shouldCheckLoggedIn = () => {
  try {
    return localStorage.getItem('auth_check') === 'check'
  } catch {
    return true
  }
}

export const setCheckLoggedIn = () => {
  try {
    localStorage.setItem('auth_check', 'check')
  } catch {}
}

export const removeCheckLoggedIn = () => {
  try {
    localStorage.removeItem('auth_check')
  } catch {}
}

export const setLoginType = (loginType: 'sso' | 'ep') => {
  try {
    localStorage.setItem('login_type', loginType)
  } catch {}
}

export const getLoginType = () => {
  try {
    const loginType = localStorage.getItem('login_type')

    if (loginType && ['sso', 'ep'].includes(loginType)) {
      return loginType as 'sso' | 'ep'
    }

    return null
  } catch {
    return null
  }
}

export function AuthContextController({ children, defaultState = DEFAULT_STATE }: AuthContextControllerProps) {
  const [authState, d] = useReducer(reducer, defaultState)
  const { setRateLimitHit } = useRateLimit()
  const { identify } = useIdentify()
  const { theme, setTheme } = useColorTheme()

  useEffect(() => {
    if (!shouldCheckLoggedIn() && window.location.pathname !== AppRoute.SSO_GATEWAY) {
      d({ type: 'LOGOUT' })
    }
  }, [])

  const dispatch = useCallback((action: AuthAction) => {
    d(action)

    if (action.type === 'LOGIN') {
      setCheckLoggedIn()
    }

    if (action.type === 'LOGOUT') {
      removeCheckLoggedIn()
    }
  }, [])

  const value = useMemo(
    () => ({
      state: authState,
      dispatch
    }),
    [authState, dispatch]
  )

  const { gsClient } = useClient()

  const email = authState?.status === 'authorized' ? authState.account.email : ''

  useMemo(() => {
    let id: number | undefined

    if (email) {
      id = gsClient.interceptors.response.use(
        responseSuccessInterceptor(dispatch, gsClient, email, setRateLimitHit),
        responseFailureInterceptor(dispatch, gsClient, email, setRateLimitHit)
      )
    }

    return () => {
      if (id) {
        gsClient.interceptors.response.eject(id)
      }
    }
  }, [email])

  const { login } = useLogin({ dispatch })

  useEffect(() => {
    const getAccountData = async () => {
      try {
        dispatch({ type: 'LOADING' })
        const loginResult = await login({})

        if (loginResult?.rateLimitHit) {
          return
        }
      } catch (err) {
        dispatch({ type: 'LOGOUT' })
      }
    }

    if (
      authState.status === 'first_load' &&
      (shouldCheckLoggedIn() || window.location.pathname === AppRoute.SSO_GATEWAY)
    ) {
      getAccountData()
    }

    if (authState.status === 'authorized' && authState.account) {
      identify({
        accountId: authState.account.id,
        traits: {
          email: authState.account.email,
          partnerId: authState.partner.id,
          partnerName: authState.partner.name
        }
      })
    }
  }, [authState.status])

  useEffect(() => {
    if (
      authState.status === 'authorized' &&
      ['sandbox_partner_000000Cw9OpSr99zyfqj6Oc7AeAO8'].includes(authState.partner.id)
    ) {
      setTheme({
        ...theme,
        primary: '#35B454',
        primaryHover: '#089A43',
        primaryActive: '#089A43',
        primaryFocus: '#089A43',
        primary100: '#F5F5F6',
        primary400: '#35B454',
        primary600: '#089A43',
        info30: '#F8FFF8',
        info100: '#DFF4E4',
        info400: '#65CF7F'
      })
    }

    if (authState.status === 'unauthorized') {
      setTheme(BASE_PALETTE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.status])

  if (authState.status === 'loading' || authState.status === 'first_load') {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Spinner size={8} />
      </Box>
    )
  }

  return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>
}

const getPropertyFromAuthorizedState =
  <T,>(block: (_: AuthorizedAuthState) => T) =>
  (authState: AuthState) =>
    authState.status === 'authorized' ? block(authState) : null

export const getCountryCodeFromAuthState = getPropertyFromAuthorizedState(
  state => state.accountCountryCode ?? (state.countryCode as Alpha3Code)
)

export const getAccountFromAuthState = getPropertyFromAuthorizedState(state => state.account)
export const getPartnerFromAuthState = getPropertyFromAuthorizedState(state => state.partner)
export const getAccountCountryCodeFromAuthState = getPropertyFromAuthorizedState(state => state.accountCountryCode)
export const getTimeZoneFromAuthState = getPropertyFromAuthorizedState(state => state.timeZone)
export const getAcceptedTermsFromAuthState = getPropertyFromAuthorizedState(state => state.acceptedTerms)
export const getAccountConfigFromAuthState = getPropertyFromAuthorizedState(state => state.accConfig)
